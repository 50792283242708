/**
 * Created by MooWPro on 07/09/2018.
 */

/* https://developers.google.com/youtube/iframe_api_reference */
$(document).ready(function() {
    document.getElementById("video_short").play();

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var player;

    window.onYouTubeIframeAPIReady = function() {
        player = new YT.Player('video_frame', {
            height: '100%',
            width: '100%',
            videoId: 'qY91IDunQOA',
            playerVars: {
                'controls': 0,
                'showinfo': 0,
                'rel': 0,
            },
            events: {
                'onReady': onPlayerReady,
            }
        });
    };

    window.onPlayerReady = function(event) {
        $('a.play-button').css('display', 'inline-block');
    };

    $(document).on('click', 'a.play-button', function(e){
        e.preventDefault();
        $('#video_banner_incloude').css('display','none');
        $('.video-home').css('display','block');
        $('.video-block').css('display','none');
        player.playVideo();
        return false;
    });

    $(document).on('click', 'a.pause-button', function(e){
        e.preventDefault();
        $('#video_banner_incloude').css('display','block');
        $('.video-home').css('display','none');
        $('.video-block').css('display','block');
        player.pauseVideo();
        return false;
    });
});
/**
 * Created by MooWPro on 13/11/2017.
 */

    var placemarks = [];
    var geocollection = {};

window.ya_map_init = function() {

    var myMap = new ymaps.Map('map', {
        center: [55.752411, 37.609407],
        zoom: 10,
        controls: ['fullscreenControl', 'zoomControl']
    }, {
        searchControlProvider: 'yandex#search'
    });

    myMap.behaviors.disable('scrollZoom');

    createMarkByCityId($('.shops-city-select').val());

    $('.shops-city-select').change(function () {
        var city_id = $(this).val();
        var slug = $(this).children('option[value=' + city_id + ']').data('slug');
        window.location.href = '/shops/' + slug;
    });

    function createMarkByCityId(city_id) {
        placemarks = [];
        for (var i = 0, l = groups.length; i < l; i++) {
            if (groups[i].id == city_id) {
                createGroup(groups[i]);
            }
        }
    }

    function createGroup(group) {
        geocollection = new ymaps.GeoObjectCollection(null, {preset: 'islands#blueIcon'});
        myMap.geoObjects.add(geocollection);
        for (var j = 0, m = group.items.length; j < m; j++) {
            var placemark = new ymaps.Placemark(group.items[j].center, {balloonContent: group.items[j].name});
            geocollection.add(placemark);
            placemarks.push({id: group.items[j].id, mark: placemark});
        }
    }

    if (groups[0].items.length <= 1) {
        myMap.setCenter([groups[0].items[0].center[0], groups[0].items[0].center[1]], 10);
        console.log(groups[0].items[0].center[0]);
    } else {
        myMap.setBounds(myMap.geoObjects.getBounds());
    }
};

$(function () {

    $('.shops-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        dots: false,
        prevArrow: $('#shops-slider-prev-arrow'),
        nextArrow: $('#shops-slider-next-arrow'),
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    });

    removeActiveShopClass();
});

window.removeActiveShopClass = function() {
    $('.shops-slider').removeClass('visible');
    $('.shops-slider:first').addClass('visible');
};

$(document).on('click', 'a.balloon-link', function (e) {
    e.preventDefault();
    var shop_id = $(this).data('shop-id');
    for (var i = 0, l = placemarks.length; i < l; i++) {
        if (placemarks[i].id == shop_id) {
            placemarks[i].mark.balloon.open();
        }
    }
    return false;
});
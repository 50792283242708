require('../scss/base.scss');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('@fortawesome/fontawesome-free/');
require('@fortawesome/fontawesome-free/css/all.min.css');

require('slick-carousel');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('foundation-sites/dist/js/foundation.min.js');

require('../fonts/GothamPro/css/GothamPro.css');
require('../fonts/GillSans/css/GillSans.css');
require('../fonts/HelveticaNeueCyr/css/HelveticaNeueCyr.css');
//require('../fonts/LineIcons/css/LineIcons.css');
require('../fonts/QuizaPro/css/QuizaPro.css');
require('../fonts/Ubuntu/css/Ubuntu.css');

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/respond.js');
require('./common/main.js');

require('./common/slick.js');
require('./common/shops.js');
require('./common/video.js');
/**
 * Created by MooWPro on 06/09/2018.
 */
$(document).ready(function(){
    $('.banner-one').slick({
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: false,
        pauseOnFocus: true,
    });
    $('.banner-two').slick({
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: false,
        pauseOnFocus: true,
    });
    $('.colors-slider').slick({
        dots: false,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: false,
        autoplay: false,
        fade: true,
        touchMove: false,
        draggable: false,
    });

    $('#color-box-black').click(function(){
        $('.colors-slider').slick('slickGoTo','1',false);
    });

    $('#color-box-gold').click(function(){
        $('.colors-slider').slick('slickGoTo','2',false);
    });


    $('.gallery-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: true,
        asNavFor: '.gallery-slider-nav'
    });
    $('.gallery-slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.gallery-slider-for',
        dots: false,
        centerMode: true,
        focusOnSelect: true
    });

});